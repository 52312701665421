import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Card,
  Grid,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";

const withStyles = makeStyles((theme) => ({
  checklistRoot: {},
  mainText: {},
  checklist: {
    
    margin: "16px auto 0 auto",
    [theme.breakpoints.up('sm')]: {
      width: "45%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "85%",
    },
  },
  checklistItem: {
    padding: "18px",
    textAlign: "left",
    borderRadius: "20px",
    boxShadow: "0px 6px 22px 0px #5B5B5B",
    alignItems: "center",
    position: "relative",
    marginBottom: "12px",
    float: "left",
    width: "90%"
  },
  checklistItemOff: {
    padding: "18px",
    textAlign: "left",
    borderRadius: "20px",
    boxShadow: "0px 6px 22px 0px #5B5B5B",
    float: "left",
    alignItems: "center",
    position: "relative",
    opacity: ".5",
    marginBottom: "12px",
    width: "90%"
  },
  itemIcon: {
    textAlign: "center",
    color: "#44a2a0",
    fontSize: "150%",
    fontWeight: "700",
    opacity: 1,
    // width: "75%",
    margin: "0 auto",
  },
  itemIconContainer: {
    "& img": {
      width: "80%",
    },
  },
  numberIcon: {
    textAlign: "center",
    color: "#44a2a0",

    fontWeight: "700",
    opacity: 1,
    width: "75%",
    [theme.breakpoints.up('sm')]: {
      margin: "-6% auto",
      fontSize: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      margin: "8% auto",
      fontSize: "130%",
    },
  },
  numberIconContainer: {
    "& img": {
      width: "60%",
    },
    // float: "left",
    width: "40px",
    minWidth: "40px",
    maxWidth: "40px",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    // borderRadius: "50%",
    // border: "2px solid rgba(177,220,220, 1)",
    // marginRight: "14px",
  },
  numberIconContainerRight: {
    float: "right",
    width: "10%",
  },
  numberIconRight: {
    position: "absolute",
    height: "50%",
    top: "20%",
    color: "#44a2a0",
    fontSize: "150%",
    fontWeight: "700",
    textAlign: "center",
  },
  itemTitle: {
    color: "#32A19F",
    fontWeight: "400",
    lineHeight: "22px",
    width: "100%",
    fontSize: "18px",
    marginBottom: "8px",
  },
  itemDescription: {
    color: "black",
    fontWeight: "400",
    fontSize: "12px",
    // lineHeight: "2px",
    width: "100%",
    fontStretch: "condense"
  },
  greenItemTitle: {
    color: "#fff",
    fontWeight: "400",
    lineHeight: "22px",
    width: "100%",
    fontSize: "16px",
    marginBottom: "8px",
    fontStretch: "condense"
  },
  greenItemDescription: {
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    width: "100%",
    fontStretch: "condense"
  },
  textContainer: {
    marginTop: "1%",
    marginBottom: "1%",
    float: "left",
    position: "relative",
    height: "100%",
    paddingRight: "1%",
    fontStretch: "condense",
  },
  greenTextContainer: {
    marginTop: "2%",
    marginBottom: "2%",
    float: "left",
    position: "relative",
    height: "100%",
    fontStretch: "condense",
  },
  itemView: {
    color: "#44a2a0",
    fontWeight: "500",
    float: "right",
    marginTop: "20px",
    // fontSize: "16px"
  },
  itemViewPosition: {
    // position: "absolute",
    // right: "18px",
    // bottom: "18px",
  },
  colorBlock: {
    backgroundColor: "#32A19F",
    float: "left",
    width: "40px",
    maxWidth: "40px",
    minWidth: "40px",
    height: "40px",
    borderRadius: "50%",
    border: "2px solid rgba(177,220,220, 1)",
    marginRight: "12px",
    "& svg": {
      display: "flex",
      height: "100%",
      alignItems: "center",
      color: "#fff",
    },
  },
  whiteColorBlock: {
    "& img": {
      width: "60%",
      padding: "10px",
    },
    backgroundColor: "#fff",
    float: "left",
    width: "50px",
    maxWidth: "50px",
    minWidth: "50px",
    height: "50px",
    borderRadius: "50%",
    border: "2px solid #fff",
    marginRight: "12px",
    // "& svg": {
    //   display: "flex",
    //   height: "100%",
    //   alignItems: "center",
    //   color: "#fff",
    // },
  },
  aboutblock: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& p": {
      marginRight: "32px",
      fontSize: "20px",
      fontWeight: "400",
      letterSpacing: "0.18",
    },
  },
  newAccountRootWrapper: {
    height: "100%",
  },
  greenCard: {
    backgroundColor: "#00000000",
    padding: "10px",
    textAlign: "left",
    color: "#fff",
    alignItems: "center",
    position: "relative",
    marginBottom: "4px",
    float: "left",
  }
}));

const GuestReportChecklist = (props) => {
  const classes = withStyles();
  return (
    <div className={classes.checklistRoot}>
      <Typography variant="h5" className={classes.mainText}>
        Trust the PayView Process
      </Typography>
      <Typography variant="body2">
        Proof of Funds | Made Simple and Secure
      </Typography>

      <div className={classes.checklist}>
        <div style={{ width: "100%", float: "left" }}>
          <Card
            className={
              props.eulaComplete && !props.reportNotFound && !props.reportAlreadyStarted
                ? classes.checklistItem
                : classes.checklistItemOff
            }
            onClick={!props.reportNotFound && !props.reportAlreadyStarted ? () => props.handleGoToEula() : () => {}}
          >
            <Grid container spacing={2}  >
              <Grid item xs={2} xl={1} style={{ display: "inline-grid",justifyItems: "center", alignItems: "center" }}>
                <div
                  className={`${
                    props.plaidComplete == false
                      ? classes.numberIconContainer
                      : classes.colorBlock
                  }`}
                >
                  {props.plaidComplete && (
                    // <img className={classes.numberIcon} src="/icons/Checkmark.png" />
                    <svg
                      className={classes.numberIcon}
                      width="27px"
                      height="22px"
                      viewBox="0 0 27 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Checkmark</title>
                      <g
                        id="Symbols"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g id="Checkmark" fill="#fff">
                          <polygon
                            id="Fill-1"
                            points="7.3639424 22 0 14.6666667 2.4540864 12.222781 7.3639424 17.1122286 24.5459136 0 27 2.44552"
                          ></polygon>
                        </g>
                      </g>
                    </svg>
                  )}
                  {!props.plaidComplete && (
                    <Typography className={classes.numberIcon}>
                      {"1"} {props.plaidComplete}
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={10} xl={11}>
                  <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
    
                  <div className={classes.textContainer}>
                    <Typography className={classes.itemTitle}>
                      Accept Legal Disclosure
                    </Typography>
                    <Typography className={classes.itemDescription}>
                      PayView is guaranteed to protect your privacy. Review and
                      accept the legal disclosure, designed to keep you safe.
                    </Typography>
                  </div>
                </div>
                <div className={classes.itemViewPosition}>
                  {!props.plaidComplete && (
                    <Typography className={classes.itemView}>
                      {"View & Accept >"}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
          </Card>

          <Card
            className={
              props.plaidComplete
                ? classes.checklistItem
                : classes.checklistItemOff
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={2} xl={1} style={{ display: "inline-grid",justifyItems: "center", alignItems: "center" }}>
                <div
                  className={`${
                    !(props.plaidComplete && props.plaidLinked)
                      ? classes.numberIconContainer
                      : classes.colorBlock
                  }`}
                >
                  {(props.plaidComplete && props.plaidLinked) && (

                    <svg
                      className={classes.numberIcon}
                      width="27px"
                      height="22px"
                      viewBox="0 0 27 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Checkmark</title>
                      <g
                        id="Symbols"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g id="Checkmark" fill="#fff">
                          <polygon
                            id="Fill-1"
                            points="7.3639424 22 0 14.6666667 2.4540864 12.222781 7.3639424 17.1122286 24.5459136 0 27 2.44552"
                          ></polygon>
                        </g>
                      </g>
                    </svg>
                  )}
                  {!props.plaidLinked && !props.reportComplete && !props.reportInProgress && (
                    <Typography className={classes.numberIcon}>{"2"}</Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={10} xl={11}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  
                  <div className={classes.textContainer}>
                    <Typography className={classes.itemTitle}>
                      Link Accounts via Plaid
                    </Typography>
                    <Typography className={classes.itemDescription}>
                      With Plaid, you can securely connect your financial accounts
                      to PayView in seconds.
                    </Typography>
                  </div>
                </div>

                <div className={classes.itemViewPosition}>
                  {props.plaidComplete && !props.plaidLinked && !props.reportComplete && !props.reportInProgress  && (
                    <Typography 
                      className={classes.itemView}
                      onClick={props.eulaComplete ? () => props.handleGoToPlaidSetup() :  () => {}}
                    >
                      {"Proceed to Plaid >"}
                    </Typography>
                  )}
                  {/* {props.plaidLinked && !props.reportComplete && !props.reportInProgress && (
                    <Typography 
                      className={classes.itemView}
                      onClick={props.eulaComplete ? () => props.handleGoToPlaidSetup() :  () => {}}
                    >
                      {"Link Another Financial Account >"}
                    </Typography>
                  )} */}
                  {/* {props.plaidLinked && !props.reportComplete && !props.reportInProgress && (
                    <Typography
                      className={classes.itemView}
                      onClick={() => props.handleReportNext()}
                    >
                      {"All set? Proceed to Step 3 >"}
                    </Typography>
                  )} */}
                </div>
              </Grid>
            </Grid>
          </Card>

        <Card
          className={
            props.plaidComplete && (props.reportInProgress || props.reportComplete)
              ? classes.checklistItem
              : classes.checklistItemOff
          }
          onClick={props.plaidComplete && props.reportComplete ? () => props.handleGoToCompleteReport() :  () => {}}
        >
          <Grid container spacing={2} >
              <Grid item xs={2} xl={1} style={{ display: "inline-grid",justifyItems: "center", alignItems: "center" }}>
                <div className={`${
                    !props.reportComplete
                      ? classes.numberIconContainer
                      : classes.colorBlock
                  }`}>
                  {props.reportComplete && (

                    <svg
                      className={classes.numberIcon}
                      width="27px"
                      height="22px"
                      viewBox="0 0 27 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Checkmark</title>
                      <g
                        id="Symbols"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g id="Checkmark" fill="#fff">
                          <polygon
                            id="Fill-1"
                            points="7.3639424 22 0 14.6666667 2.4540864 12.222781 7.3639424 17.1122286 24.5459136 0 27 2.44552"
                          ></polygon>
                        </g>
                      </g>
                    </svg>
                  )}
                  {!props.reportComplete && (
                    <Typography className={classes.numberIcon}>{"3"}</Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={10} xl={11}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.textContainer}>
                    <Typography className={classes.itemTitle}>
                      {
                        !props.plaidComplete ? "Complete Your Report" 
                          : props.reportComplete ? "Report Complete!" : "Report Building Underway"
                      }
                    </Typography>
                    <Typography className={classes.itemDescription}>
                      {
                          props.reportComplete ? "Your report has been sent to you and your realtor." : "Check your email for link when its ready."
                      }
                    </Typography>

                  </div>
                </div>

                <div className={classes.itemViewPosition}>
                  {props.reportComplete && (
                    <Typography className={classes.itemView}>
                      {"View Your Proof of Funds >"}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>

        </Card>
        </div>
      </div>
      <div className={classes.aboutblock}>
        <p>About PayView</p>
        <p>Log In</p>
      </div>
    </div>
  );
};

export default GuestReportChecklist;