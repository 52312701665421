import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import Amplify, { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { addToken } from '../actions/addToken'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import FacebookIcon from '@material-ui/icons/Facebook';


const withStyles = makeStyles(() => ({
    googleSignInButton: {
        backgroundColor: "white",
        textTransform: "none",
        margin: "20px 0px 10px 0"
    },
    googleLogo: {
        width: "20px",
        marginRight: "7px"
    },
    facebookSignInButton: {
        backgroundColor: "#385499",
        textTransform: "none",
        color: "white",
        margin: "0px 0px 10px 0"
    },
    facebookLogo: {
        width: "20px",
        marginRight: "7px"
    },
    signinButtonWrapper: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto"
    },
    signInButton: {
        textTransform: "none",
        margin: "10px 0 10px 0",
        backgroundColor: "#0077cc",
        color: "white",
    },
    signInText: {
        textAlign: "left",
        fontSize: "1.0rem",
        fontWeight: "600"
    },
    formInput: {
        fontSize: "0.875rem",
        height: "25px"
    },
    formCard: {
        display: "flex",
        flexDirection: "column", 
        padding: "15px",
        alignItems: "center"
    },
    errorText: {
        width: '80%',
        fontSize: '.8rem',
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "5%"
    },
    landingHeaderWrapper: {
        display: "flex",
        align: "center",
        marginBottom: "2%"
    },
    payviewLogo: {
        height: "60px",
        margin: "auto",
        marginTop: "8%"
        // paddingRight: "15px"
    },
    payviewText: {
        marginBottom: "3%",
        fontSize: "90%"
    },
    loginbox: {
        background: "#fff",
        color: "#57a68c",
        padding: "2%"
    },
    payviewSubText: {
        fontSize: "80%"
    },
}));

Amplify.configure({
    aws_cognito_region: process.env.REACT_APP_AWS_REGION, // (required) - Region where Amazon Cognito project was created   
    aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL_ID, // (optional) -  Amazon Cognito User Pool ID   
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    aws_mandatory_sign_in: "enable", // (optional) - Users are not allowed to get the aws credentials unless they are signed in 
})

const SignInForm = props  => {
    const history = useHistory();
    const classes = withStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [signinError, setSigninError] = useState({code: 'NotAuthorizedException', name: 'NotAuthorizedException', message: 'Incorrect username or password.'});
    const dispatch = props.dispatch;

    useEffect(()=>{
        const token = localStorage.getItem("token")
        if(token !== 'null' && token !== null) {
          history.push("/dashboard");
        }
    }, [])

    const signIn = async () => {
        try {
            const user = await Auth.signIn(username, password);
            console.log('sign in successful!')

            dispatch(addToken(user.signInUserSession.idToken.jwtToken))
            setSigninError(null)
            history.push("/dashboard");

        } catch (error) {
            console.log('error signing in', error);
            setSigninError(error)
        }
    }

    // const handleSignIn = (event) => {
    //     event.preventDefault();

    //     signIn();

    //     event.target.reset();
    // }

    const signInGoogle = () => {
        try {
            window.location.href = process.env.REACT_APP_GOOGLE_LOGIN_URL;
        }
        catch (error) {
            console.log(error)
        }
    }
    const signInFacebook = () => {
        try {
            window.location.href = process.env.REACT_APP_FACEBOOK_LOGIN_URL;
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <div className={classes.loginbox}>
            <div className={classes.landingHeaderWrapper}>
                <img className={classes.payviewLogo} src="/FastRealty-Logo-1.png" />
            </div>
            <h3 className={classes.payviewSubText}>Work Smarter, Close Faster</h3>
            
            <Card>
                {/* <form className={classes.formCard} onSubmit={handleSignIn}>
                    <Typography className={classes.signInText}>Username</Typography>
                    <input
                        className={classes.formInput}
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                    >
                    </input>
                    <Typography className={classes.signInText}>Password</Typography>
                    <input
                        className={classes.formInput}
                        type="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    >
                    </input>
                    <a style={{ color: "white", fontSize: "12px" }} href="#">Forgot Your Password?</a>
                    <Button className={classes.signInButton} variant="contained" type="submit">Sign In</Button>
                </form> */}
                
            </Card>
            
            <div className={classes.signinButtonWrapper}>
            {/* {signinError && 
                    <Typography className={classes.errorText}>{signinError.message}</Typography>
            } */}
                <Button
                    className={classes.googleSignInButton}
                    onClick={signInGoogle}
                    variant="contained"

                >
                    <img className={classes.googleLogo} src="/google_logo.png" />
                Sign in with Google
            </Button>
                <Button
                    className={classes.facebookSignInButton}
                    onClick={signInFacebook}
                    variant="contained"
                >
                    <FacebookIcon className={classes.facebookLogo} />
                Sign in with Facebook
            </Button>
            </div>
        </div>

    )
}

export default connect((state) => ({
    dashboardData: state.dashboardData,
    dashboardDataLoading: state.dashboardDataLoading
}))(SignInForm);
