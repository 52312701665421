import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import {Auth} from 'aws-amplify'
import { Link, useHistory } from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles'
import { Typography, Dialog, DialogContent, DialogActions, CircularProgress, DialogTitle, Button } from '@material-ui/core'
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';


const withStyles = makeStyles(() => ({
    profileRoot:{
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    profileHeaderInfoWrapper: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        margin: "20px"
    },
    profileWrapper: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        margin: "40px"
    },
    profileInfoWrapper: {
        background: "#ffffff",
        borderTopLeftRadius: "51px",
        borderTopRightRadius: "51px",
        height: "100vh" 
    },
    profileInfoHeader: {
        color: "#949494",
        fontSize: "0.75rem"
    },
    subPlanRow: {
        color: "#313131",
        fontSize: "0.75rem",
        marginTop: "10px",
        marginBottom: "10px",
        textAlign: "right"
    },
    profileInfoText: {
        color: "#313131",
        fontSize: "0.75rem"
    },
    subPlanRowLabel: {
        color: "#949494",
        fontSize: "0.75rem",
        float: "left"
    },
    profileName: {
        fontSize: "1.4rem"
    },
    profileSinceYear: {
        fontSize: "0.75rem"
    },
    accountsWrapper: {
        marginBottom: "10px",
        marginLeft: "45px"
    },
    accountsDivider: {
        marginLeft: "-45px"
    },
    subAccountsWrapper: {
        display: "flex",
        flexDirection: "column"
    },
    linkedAccountsBankname: {
        fontSize: "0.75rem",
        color: "#313131",
        marginTop: "10px"
    },
    appVersion: {
        fontSize: "0.75rem",
        color: "#949494",
        marginTop: "10px",
        textAlign: "center"
    },
    linkedAccountsSubaccounts: {
        color: "#949494",
        fontSize: "0.65rem",
        margin: "10px 0"
    },
    accountsNumber: {
        color: "black", 
        fontSize: "0.65rem",
        marginBottom: "10px"
    },
    upgradeWrapper: {
        justifyContent: "space-between",
        marginTop: "25px",
        background: "linear-gradient(.75turn, #46a7ba 0%, #1faa60 100%)",
        borderRadius: "14px",
        height: "120px",
        paddingLeft: "1rem",
        paddingTop: "2rem"
    },
    managePlanWrapper: {
        justifyContent: "space-between",
        marginTop: "25px",
        background: "linear-gradient(.75turn, #46a7ba 0%, #1faa60 100%)",
        borderRadius: "14px",
        height: "63px",
        paddingLeft: "1rem",
        paddingTop: "2rem"
    },
    upgradeTextTop: {
        color: "white",
        fontSize: "1.2rem",
        marginBottom: "3px"
    },
    upgradeTextMiddle: {
        color: "white",
        fontSize: "1.5rem",
        fontWeight: "600"
    },
    upgradeTextBottom: {
        color: "white",
        fontSize: "0.75rem",
        opacity: ".8"
    }
}))
const AUTH_MODEL_NONE = 1
const AUTH_MODEL_STAFF = 2
const AUTH_MODEL_FREE_DEMO = 3
const AUTH_MODEL_SUBSCRIBER = 4

const mapStateToProps = (state) => ({
    token: state.token
})

const MyAccount = props => {
    const classes = withStyles();
    const history = useHistory();
    const [myAccountData, setMyAccountData] = useState(null);
    const [token, setToken] = useState(null || props.token)
    const [error, setError] = useState(null);
    const [stripeError, setStripeError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [apiVersion, setApiVersion] = useState('');

    if(!props.token || token === null) {
        history.push("./")
    }

    const handleUpgradeClick = () => {
        console.log('handleUpgradeClick - getting stripe url')
        fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/stripe_checkout`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
          .then(res => res.json())
            .then(
                (result) => {
                    setStripeError(false);
                    console.log(result.checkout_url)
                    window.location.href = result.checkout_url; 
                },
                (error) => {
                    console.log('here')
                    setIsLoaded(false);
                    setStripeError(error);
                    // history.push("./");
                }
              )
        
    };

    const handleManagePlanClick = (isStaff) => {
        if(!isStaff) {
            console.log('handleManagePlanClick -- todo')
            fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/stripe_portal`, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            // .then(res => {
            //     if(res.status === 401) {history.push("/");}
            //   })
              .then(res => res.json())
              .then(
                (result) => {
                    if(result && result.portal_url) {
                        setStripeError(false);
                        console.log(result.portal_url)
                        window.location.href = result.portal_url; 
                    }
                },
                (error) => {
                    console.log(error)
                    setStripeError(true);
                }
            )
        } else {
            console.log('handleManagePlanClick -- staff so no-op')
        }
    };

    const handleStripeErrorModalClose= () => {
        setStripeError(false);
        // dispatch(clearDashboardError());
    };

    function capitalizeNames(str) {
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    useEffect(async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/myaccount`, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                  setIsLoaded(true);
                  setMyAccountData(result);
                },
                (error) => {
                    setIsLoaded(false);
                    setError(error);
                    history.push("./");
                }
              )
    
        } catch (error) {
            setIsLoaded(false);
            setError(error);
            history.push("./");
        }
          
      }, [])

    useEffect(async () => {
        const result = await fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/version`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                setApiVersion(result.app_version);
            }
          )
    }, [])  
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded || !myAccountData || !myAccountData.accounts || myAccountData.accounts.length === 0 || !myAccountData.accounts.accountInfo) {
        return <div>Loading...</div>;
      } else {
        const {accountInfo} = myAccountData.accounts;
        const displayname = accountInfo[0] && accountInfo[0].name.trim().length > 0 ? 
            capitalizeNames(accountInfo[0].name) + "'s" :
            "My"
        const authLevel = accountInfo[0] && accountInfo[0].auth_model;
        const isStaff =  accountInfo[0] && accountInfo[0].auth_model === AUTH_MODEL_STAFF
        const freeReportsRemaining = accountInfo[0] && accountInfo[0].free_reports

        return (
            <div className={classes.profileRoot}>
            <Layout pageTitle={props.title}/>
                <div className={classes.profileHeaderInfoWrapper}>
                    <Typography className={classes.profileSinceYear}>
                    </Typography>
                    <Typography
                        className={classes.profileName}
                    >
                        {`${displayname} PayView`}
                    </Typography>
                </div>
                <div className={classes.profileInfoWrapper}>
                    <div className={classes.profileWrapper}>
                        <Typography style={{color: "black", marginBottom: "15px"}}>Account Information</Typography>
                        <div style={{marginBottom: "10px"}}>
                            <Typography className={classes.profileInfoHeader}>Name</Typography>
                            <Typography
                                className={classes.profileInfoText}
                            >{capitalizeNames(accountInfo[0].name)}</Typography>
                        </div>
                        <Divider />
                        <div style={{marginBottom: "10px"}}>
                            <Typography className={classes.profileInfoHeader}>Email</Typography>
                            <Typography
                                className={classes.profileInfoText}
                            >{accountInfo[0].email}</Typography>
                        </div>
                        <Divider />
                        <div style={{marginBottom: "10px"}}>
                            <Typography className={classes.profileInfoHeader}>Phone</Typography>
                            <Typography
                                className={classes.profileInfoText}
                            >{accountInfo[0].phone}</Typography>
                        </div>
                        <Divider />
                        <div style={{marginBottom: "10px"}}>
                            <Typography className={classes.profileInfoHeader}>Address</Typography>
                            <Typography
                                className={classes.profileInfoText}
                            >{accountInfo[0].address}</Typography>
                        </div>
                        <Divider />
                        <a style={{marginTop: "10px", visibility: "hidden"}}>
                            <Typography style={{fontSize: "0.65rem", color: "#32A19F"}}>Change Password</Typography>
                        </a>
                    </div>
                    <div className={classes.profileWrapper}>
                        <Typography style={{color: "black"}}>My PayView Plan</Typography>
                        {authLevel === 3 && // free
                            <span>
                                <Typography className={classes.subPlanRow} style={{align: "right"}}>
                                    <span className={classes.subPlanRowLabel}>Free</span> 
                                    {`${freeReportsRemaining} of 2 Free Reports Remaining`}
                                </Typography>
                                <Divider />
                                <div onClick={() => handleUpgradeClick()}>
                                    <div className={classes.upgradeWrapper}>
                                        <Typography className={classes.upgradeTextTop}>
                                            My PayView Plan
                                        </Typography>
                                        <Typography className={classes.upgradeTextMiddle}>
                                            Unlimited Reports &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&gt;
                                        </Typography>
                                        <Typography className={classes.upgradeTextBottom}>
                                            $40/mo
                                        </Typography>
                                    </div>
                                </div>
                            </span>
                        }
                        
                        {(authLevel === 4 || authLevel === 2) && // staff and subscribers
                            <span>
                                <Typography className={classes.subPlanRow} style={{align: "right"}}>
                                    <span className={classes.subPlanRowLabel}>{isStaff ? `Staff` : `Premium - $40/month`}</span> 
                                    Unlimited Reports
                                </Typography>
                                <Typography className={classes.subPlanRow}>
                                    <span className={classes.subPlanRowLabel}>Next Payment</span> 
                                    {authLevel===4 ? new Date(accountInfo[0] && accountInfo[0].subscr_expires).toDateString() : 'N/A'}
                                </Typography>
                                <Divider />
                                <Typography className={classes.subPlanRow}>
                                    <span className={classes.subPlanRowLabel}>Bill To </span> 
                                    <span>****</span>{/* **** 1234 */}
                                </Typography>
                                <Divider />
                                <div onClick={() => handleManagePlanClick(isStaff)}>
                                    <div className={classes.managePlanWrapper}>
                                        <Typography className={classes.upgradeTextTop}>
                                            Manage Plan & Billing &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&gt;
                                        </Typography>
                                    </div>
                                </div>
                            </span>
                        }
                        
                    </div>

                    <div className={classes.profileWrapper}>
                        <Divider />
                        <Typography className={classes.appVersion}>{`${apiVersion}`}</Typography>
                    </div>
                </div>
                <Dialog
                    open={stripeError}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle className={classes.loadingDialog}>ERROR</DialogTitle>
                    <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                        <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                            Error managing account at this time. Please try again.
                        </Typography>
                    </DialogContent>
                    <DialogActions className={classes.loadingDialog}>
                        <Button className={classes.dialogButton} onClick={() => handleStripeErrorModalClose()}>
                        Okay
                        </Button>
                    </DialogActions>
                </Dialog>
                <div>{props.children}</div>
            </div>
        )
      }
}

export default connect(mapStateToProps)(MyAccount);