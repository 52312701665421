import React from "react";
import Layout from "./Layout";
import { makeStyles } from "@material-ui/core/styles";

import { connect, useSelector } from "react-redux";



const withStyles = makeStyles(() => ({
  dashboardRoot: {
    minHeight: "100vh",
    width: "100%",
    // display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "auto",
  },
  root: {
    "&[disabled]": {
      color: "grey",
      cursor: "default",
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  linkedAssetWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    padding: "15px",
  },
  linkedAssetNum: {
    fontSize: "44px",
    fontWeight: "500",
  },
  linkedAssetTitle: {
    fontSize: "18px",
    fontWeight: "300",
  },
  dbTopText: {
    paddingTop: "3%",
    paddingLeft: "5%",
    paddingRight: "3%",
    paddingRight: "2%",
    textAlign: "left"
  },
  linkedAccountsCardsWrapper: {
    justifyContent: "space-evenly",
    whiteSpace: "nowrap",
    position: "relative",
  },
  linkedAccountsCardsWhite: {
    overflowX: "scroll",
  },
  linkedAccountsCards: {
    padding: "15px",
    maxWidth: "12vh",
    height: "15vh",
    marginRight: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "20px",
    boxShadow: "0 6px 22px 0 RGBA(91, 91, 91, 0.5)",
    display: "inline-block",
  },
  linkAccountsAddHex: {},
  requestIcon: {
    // height: "auto",
    // margin: "auto",
    // marginTop: "0px",
    // marginBottom: "10px",
    width: "30%",
    marginTop: "4%",
    margin: "auto",
    marginBottom: "6%"
  },
  requestCardTitle: {
    marginBottom: "10px",
    marginTop: "0",
    fontSize: "1rem",
    textAlign: "center",
    fontWeight: "700",
    color: "#313131",
  },
  fundsProofCardWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  fundsProofWrapper: {
    background: "#ffffff",
    borderRadius: "51px",
    marginLeft: '5%',
    marginRight: '5%',
    background: "#ffffff",
    '@media (max-width: 899px)' : {
      marginLeft: '0%',
      marginRight: '0%',
      paddingLeft: '5%',
      paddingRight: '5%',
      height: '100%'
    }
  },
  fundsProofHeader: {
    color: "#313131",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "700",
    marginTop: "15px",
  },
  fundsProofSubHeader: {
    fontSize: "16px",
    color: "#313131",
    fontWeight: "600",
    textAlign: "left",
  },
  fundsProofHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "40px",
    marginLeft: "30px",
    marginBottom: "20px",
  },
  notificationsWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#313131",
    marginTop: "20px",
    fontSize: "1.0rem",
    "& p": {
      fontWeight: "700",
    },
  },
  newMessagesWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
    background: "rgba(210,227,246,0.30)",
    borderRadius: "14px",
    height: "40px",
  },
  messageCount: {
    borderRadius: "20px",
    backgroundColor: "#44a2a0",
    height: "60%",
    width: "25px",
    color: "#fff",
    margin: "8px",
    fontSize: "14px",
  },
  bankName1: {
    fontSize: "11px",
    fontWeight: "400",
    textAlign: "left",
    float: "left",
    width: "100%",
  },
  bankAmount: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#464646",
    marginBottom: "10px",
    float: "left",
    width: "100%",
  },
  accountImg: {
    float: "left",
    width: "35%",
    marginBottom: "10px",
  },
  paperRoot: {
    width: "100%",
    margin: "0 auto",
  },
  linkAccountsAddHex: {
    width: "auto",
    height: "30px",
    marginBottom: "auto",
  },
  bankName: {
    fontSize: "0.9rem",
    textAlign: "left",
  },
  accountValue: {
    fontSize: "1rem"
  },
  bankNameAccountCount: {
    fontSize: "0.65rem",
    textAlign: "left",
  },
  bankNameWrapper: {
    marginBottom: "5px"
  },
  requestCard: {
    height: "15vw",
    width: "25vw",
    minWdth: "25vw",
    borderRadius: "14px",
    marginRight: "5px",
    marginLeft: "5px",
    boxShadow: "0 6px 22px 0 rgba(91,91,91,0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center", 
    marginTop: "15px",
    '@media (max-width: 899px)' : {
      width: "auto",
      height: "auto"
    }
  },
  fundsProofCardWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  fundsProofHeader: {
    color: "#313131",
    textAlign: "left",
  },
  fundsProofSubHeader: {
    fontSize: "0.75rem",
    color: "#313131",
    textAlign: "left",
  },
  notificationsWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#313131",
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontSize: "1.0rem",
  },
  newMessagesWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
    background: "rgba(210,227,246,0.30)",
    borderRadius: "14px",
    height: "40px",
  },
  messageCount: {
    borderRadius: "20px",
    backgroundColor: "#44a2a0",
    height: "60%",
    width: "25px",
    color: "white",
    margin: "8px",
  },
  popover: {
    backgroundColor: "#000",
    opacity: ".7",
    color: "#fff",
  },
  popoverDiv: {
    textAlign: "left",
    fontSize: "1.5rem",
    marginLeft: "2vh",
    marginRight: "2vh",
    padding: "2vh",
    color: "white",
    backgroundColor: "#13474A",
    height: "16vh",
    width: "30vh",
    borderRadius: "2vh",
  },
  popoverCloseButton: {
    color: "white",
    float: "right",
    left: "3.5vh",
    bottom: "2vh",
    fontSize: "1.3rem",
  },
  popoverOutline: {
    outline: "solid grey 2px",
  },
  bankLogos: {
    height: "22px",
    width: "22px",
    margin: "auto",
  },
  bankLogoWrapper: {
    display: "flex",
    justifyContent: "center",
    /* vertical-align: -webkit-baseline-middle, */
    backgroundColor: "white",
    boxShadow: "0px 0px 5px 5px rgb(187 183 183 / 26%)",
    borderRadius: "30px",
    width: "27px",
    height: "27px",
  },
  linkedAccountsBankWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  loadingDialog: {
    backgroundColor: "#000",
    opacity: ".7",
    color: 'white',
    paddingBottom: "20px"
  },
  spinner: {
      marginRight: '20px'
      
  },
  dialogText: {
      fontSize: '1.15rem'
  },
  dialogButton: {
    color: "white"
  },
  popovers: {
    zIndex: "10",
  },
  notificationCard: {
    marginTop: "1.5vh",
    marginBottom: "1.5vh",
    // width: "90%",
    height: "10vh"
  },
  dontShowOnMobile: {
    '@media (max-width: 899px)' : {
      display: 'none'
    }
  },
  headerText: {
    marginBottom: 0,
    marginTop: 0,
    fontWeight: 400,
    '@media (max-width: 899px)' : {
      paddingBottom: '3%'
    }
  },
  leftGrid: {
    borderRight: '#bbb  solid', paddingLeft: '5%', paddingTop: '2%', marginTop: '2%', marginBottom: '5%', paddingLeft: '4% !important',
    '@media (max-width: 899px)' : {
      borderRight: 'none',
      marginBottom: "0%"
    }
  },
  leftGridTitle: {
    'margin':  0,'fontWeight': 'bold','fontSize': '70%', marginBottom: '5%',
    '@media (max-width: 899px)' : {
      marginBottom: 0,
      paddingLeft: '1%',
      fontSize: '100%'
    }
  },
  rightGrid: {
    color: '#000', textAlign: 'left', paddingLeft: '3%', paddingTop: '2%', marginTop: '2%', maxHeight: '60vh', overflowX: "hidden",
    '@media (max-width: 899px)' : {
      maxHeight: '6000vh'
    }
  },
  rightGridTitle: {
    'margin':  0,'fontWeight': 'bold','fontSize': '70%',
    '@media (max-width: 899px)' : {
      fontSize: '100%'
    }
  },
  mainGrid: {
    color: '#000', textAlign: 'left', paddingRight: "3%", paddingBottom: "2%",
    '@media (max-width: 899px)' : {
      paddingBottom: "0%"
    }
  },
  cardTopLine: {
    fontWeight: "bold", 
    fontSize: ".4em",
    '@media (max-width: 899px)' : {
      fontSize: ".7em",
    }
  },
  cardSecondaryLine: {
    fontSize: ".35em",
    '@media (max-width: 899px)' : {
      fontSize: ".6em",
    }
  }
}));

const FastRealty = (props) => {
  const classes = withStyles();
  const token = useSelector((state) => state.token);
  const url = `https://streamlit-pocs.onrender.com/Magic_Emails?token=${token}&embed=true&embed_options=light_theme`

  return (
    <div className={classes.dashboardRoot} >
      <Layout pageTitle={props.title} />
      <iframe
        src={url}
        style={{height: "100vh", width: "100%"}}
      ></iframe>
    </div>
  );
};

export default connect((state) => ({}))(FastRealty);
